import React from "react";
import { Box, Button } from "@mui/material";
import i18n from "i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
  LanguagePicker,
  MobileLanguagePicker,
} from "./LanguagePicker";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";

import {
  ControlledMenu,
  MenuItem as FancyMenuItem,
  useHover,
  useMenuState,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";

const items = [
  { label: "menu.home", url: "/" },
  { label: "menu.who-we-are", url: "/who-we-are" },
  {
    label: "menu.what-we-do",
    submenus: [
      { label: "menu.our-objectives", url: "/what-we-do" },
      { label: "menu.mindlab", url: "/mindlab" },
      {
        label: "menu.projects-portfolio",
        url: "/projects-portfolio",
      },
      {
        label: "menu.ongoing-projects",
        url: "/ongoing-projects",
      },
    ],
  },
  { label: "menu.blog", url: "/blog" },
  { label: "menu.get-involved", url: "/get-involved" },
];

export const Menu = () => {
  const [isMenuExpanded, setIsMenuExpanded] =
    React.useState(false);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, i18n.language]);

  const { t } = useTranslation();

  React.useEffect(() => {
    const clickHandler = (e) => {
      // basically, whereever the user clicks we have to hide the menu
      // CODE just in case we need it later on
      setIsMenuExpanded(false);
    };

    if (isMenuExpanded) {
      const $el = document.querySelector("#menu-options");

      document.addEventListener("click", clickHandler);
    }

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [isMenuExpanded]);

  const navigate = useNavigate();

  return (
    <div>
      <Box className="desktop-page-menu">
        {items.map((item, index) => (
          <MenuItem
            {...item}
            key={index}
            isActive={pathname === item.url}
          />
        ))}
        <div
          className="menu-item menu-item-redirect35"
          onClick={() => {
            navigate("/redirect35", {
              preventScrollReset: false,
            });
          }}
        >
          {t("menu.redirect")}
        </div>
        <div
          className="menu-item menu-item-donate"
          onClick={() => {
            navigate("/donate", {
              preventScrollReset: false,
            });
          }}
        >
          {t("menu.donate")}
        </div>

        <LanguagePicker />
      </Box>
      <Box className="mobile-page-menu">
        {/* <LanguagePicker
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          setIsMenuExpanded={setIsMenuExpanded}
        /> */}
        <Button
          sx={{ width: "50px", height: "100%" }}
          onClick={(e) => {
            setIsExpanded(false);
            e.preventDefault();
            e.stopPropagation();
            setIsMenuExpanded(!isMenuExpanded);
          }}
        >
          <MenuIcon />
        </Button>
        {isMenuExpanded ? (
          <MobileMenuContent
            isMenuExpanded={isMenuExpanded}
          />
        ) : // <Box
        //   id="menu-options"
        //   sx={{
        //     position: "absolute",
        //     top: "80px",
        //     right: "0px",
        //     width: "170px",
        //     padding: "10px 0",
        //     background: "#FFFFFF",
        //     border: "1px solid #dedede",
        //   }}
        // >
        //   {items.map((item, index) => (
        //     <MenuItem {...item} key={index} isMobile />
        //   ))}
        //   <div className="menu-item menu-item-donate">
        //     {t("menu.donate")}
        //   </div>
        // </Box>
        null}
        {/* <DonateNow /> */}
        {/* <LanguagePicker /> */}
      </Box>
    </div>
  );
};

const MenuItem = (props) => {
  const { submenus, isMobile } = props;

  if (submenus) {
    return <ComplexMenuItem {...props} />;
  }

  return <SimpleMenuItem {...props} />;
};

const SimpleMenuItem = (props) => {
  const { label, url, isActive, submenus } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <div
      className={`menu-item ${
        isActive ? "menu-item-active" : ""
      }`}
      onClick={() => {
        navigate(url, { preventScrollReset: false });
      }}
    >
      {t(label)}
    </div>
  );
};

const ComplexMenuItem = (props) => {
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);
  const { anchorProps, hoverProps } = useHover(
    isOpen,
    setOpen
  );

  const { label, url, isActive, submenus } = props;

  const { t } = useTranslation();

  return (
    <>
      <div
        ref={ref}
        {...anchorProps}
        className={`menu-item ${
          isActive ? "menu-item-active" : ""
        }`}
      >
        {t(label)}
      </div>

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
      >
        {submenus.map(({ label, url }, index) => {
          return (
            <FancyMenuItem key={index}>
              <SimpleMenuItem label={label} url={url} />
            </FancyMenuItem>
          );
        })}
      </ControlledMenu>
    </>
  );
};

const MobileMenuContent = (props) => {
  const { isMenuExpanded } = props;

  const menuItems = [
    { label: "menu.home", url: "/" },
    { label: "menu.who-we-are", url: "/who-we-are" },
    {
      label: "menu.what-we-do",
      submenus: [
        {
          label: "menu.our-objectives",
          url: "/what-we-do",
        },
        { label: "menu.mindlab", url: "/mindlab" },
        {
          label: "menu.projects-portfolio",
          url: "/projects-portfolio",
        },
        {
          label: "menu.ongoing-projects",
          url: "/ongoing-projects",
        },
      ],
    },
    { label: "menu.blog", url: "/blog" },
    { label: "menu.get-involved", url: "/get-involved" },
  ];

  // const [isMenuExpanded, setIsMenuExpanded] =
  //   React.useState(true);
  const [isSubmenuStateActive, setIsSubmenuStateActive] =
    React.useState(false);
  const [selectedMenuItem, setSelectedMenuItem] =
    React.useState(-1);

  const { t } = useTranslation();

  const hideSubmenuBox = () => {
    setIsSubmenuStateActive(false);
    setTimeout(() => {
      setSelectedMenuItem(-1);
    }, 500);
  };

  const navigate = useNavigate();

  return (
    <div className="mobile-menu-items-wrapper">
      {/* display the main menu items */}
      {menuItems.map((menuItem, index) => {
        const { label, url, submenus } = menuItem;

        return (
          <div
            onClick={(e) => {
              if (submenus && submenus.length > 0) {
                e.preventDefault();
                e.stopPropagation();

                setIsSubmenuStateActive(true);
                setSelectedMenuItem(index);
              } else {
                navigate(url, {
                  preventScrollReset: false,
                });
              }
            }}
            className="menu-item"
            key={index}
          >
            {submenus ? `${t(label)} >` : t(label)}
          </div>
        );
      })}

      <div
        className="menu-item menu-item-donate"
        onClick={() => {
          navigate("/donate", {
            preventScrollReset: false,
          });
        }}
      >
        {t("menu.donate")}
      </div>

      <MobileLanguagePicker />

      <div
        className="mobile-first-level-wrapper"
        style={{
          transform: `translate(${
            isSubmenuStateActive ? 0 : "200px"
          }, 0`,
        }}
      >
        {selectedMenuItem !== -1 ? (
          // display the submenus for the selected menu item
          <>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                hideSubmenuBox();
              }}
              className="menu-item"
            >{`< ${t("menu.back")}`}</div>
            {menuItems[selectedMenuItem].submenus.map(
              (menuItem, index) => {
                const { label, url } = menuItem;

                return (
                  <div
                    onClick={() => {
                      navigate(url, {
                        preventScrollReset: false,
                      });
                    }}
                    className="menu-item"
                    key={index}
                  >
                    {t(label)}
                  </div>
                );
              }
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
