import React from "react";

import { Newsletter } from "./Newsletter";
import { useTranslation } from "react-i18next";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

export const OngoingProjects = () => {
  const { i18n } = useTranslation();

  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/ongoing-project?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data: { attributes } }) => {
        console.log(attributes);
        setDetails(attributes);
      });
  }, [i18n.language]);

  return (
    <div className="ongoing-projects-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />

        <div className="message">
          <div className="inner-wrapper">
            {details?.textOverImage}
          </div>
        </div>
      </div>

      <div className="page-title-section">
        {details?.textDescription}
      </div>

      <div className="project-list">
        {details?.ongoingProject?.map(
          (ongoingProject, index) => (
            <OngoingProject
              {...ongoingProject}
              key={index}
            />
          )
        )}
      </div>
    </div>
  );
};

const OngoingProject = (props) => {
  const {
    campaignName,
    campaignImage,
    campaignDescription,
    campaignCallToAction,
  } = props;

  return (
    <div className="ongoing-project">
      <div className="ongoing-project-title">
        {campaignName}
      </div>
      <div className="ongoing-project-first-line">
        <div className="ongoing-project-image">
          <img src={campaignImage?.data?.attributes?.url} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="ongoing-project-description">
            <BlocksRenderer content={campaignDescription} />
          </div>
        </div>
      </div>
      <div className="ongoing-project-cta">
        <BlocksRenderer content={campaignCallToAction} />
      </div>
    </div>
  );
};
