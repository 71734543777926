import React from "react";

import { Newsletter } from "./Newsletter";
import { useTranslation } from "react-i18next";

export const ProjectsPortfolio = () => {
  const { i18n } = useTranslation();

  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}
/api/our-project?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data: { attributes } }) => {
        console.log(attributes);
        setDetails(attributes);
      });
  }, [i18n.language]);

  return (
    <div className="our-products-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />

        <div className="message">
          <div className="inner-wrapper">
            {details?.textOverImage}
          </div>
        </div>
      </div>

      <div className="page-title-section">
        {details?.textDescription}
      </div>

      <div className="project-list">
        {details?.projects?.map((project, index) => (
          <Project {...project} key={index} />
        ))}
      </div>
    </div>
  );
};

const Project = (props) => {
  const { title, description, image } = props;
  return (
    <div className="project-wrapper">
      <div className="title">{title}</div>
      <div className="image-wrapper">
        <img src={image?.data?.attributes?.url} />
      </div>
      <div className="description">{description}</div>
    </div>
  );
};
